<template>
    <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
            <a :href="certification.pivot.url" target="_blank">
                <v-avatar class="mt-2 mr-2 item-custom" v-bind="attrs" v-on="on" @click="openDialog">
                    <v-img :src="certification.image_certification"
                        :lazy-src="certification.image_certification"
                        :alt="certification.name"
                        class="certification-icon-card"
                        v-bind="attrs"
                        v-on="on"
                    />
                </v-avatar>
            </a>
        </template>

        <span>{{ certification.name }}</span>
    </v-tooltip>
</template>
<script>
export default {
    name: "ItemCertification",
    props: ["certification"],
    data(){
        return {
            dialog: false,
        }
    },
    methods: {
        openDialog(){
            this.dialog = true;
        },
        closeDialog(){
            this.dialog = false;
        }
    }
}
</script>
<style>
.certification-image{
    border-radius: 0.4rem;
}
.item-custom{
    cursor: pointer;
}
</style>